<button
  [class]="buttonClasses"
  [disabled]="disabled || isLoading"
  class="loading-button">
  <ng-container *ngIf="!isLoading; else loading">
    <i *ngIf="icon" [class]="icon" style="margin-right: 4px"></i>
    {{ text }}
  </ng-container>
  <ng-template #loading>
    <span class="spinner"></span>
  </ng-template>
</button>
