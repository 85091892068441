import { Component, Input } from '@angular/core';
import { btnColor, btnSize, btnTextColor } from '@libs/btn/btn.types';

@Component({
  selector: 'app-btn',
  templateUrl: './btn.component.html',
  styleUrls: ['./btn.component.scss'],
})
export class BtnComponent {
  // TODO REMPLACER LES ICONS PAR LES ICONS DE MATERIAL
  /*@Input() predefinedIcon: keyof typeof PredefinedIcons;*/
  @Input() isLoading = false;
  @Input() size: btnSize = 'medium';
  @Input() icon = '';
  @Input() text = '';
  @Input() disabled: boolean | undefined = false;
  @Input() textColor: btnTextColor = 'none';
  @Input() color: btnColor = 'primary';
  get buttonClasses(): string {
    return `${this.size} ${this.color} ${this.textColor}`;
  }

  /*  getIconClass(): string {
      return PredefinedIcons[this.predefinedIcon];
    }*/
}
